import { axiosProvider } from "../../../../shared/config/axios.config";

async function getAllCRM() {
    const response = await axiosProvider.get('/CRM');
    const crm = response.data;
    return crm;
}

async function getCRMByTopicId(topicId) {
    try {
        const response = await axiosProvider.get(`/CRM/crmTopics/topic/${topicId}`);
        const crm = response.data;
        return crm
    } catch (error) {
        return null
    }
}

async function createCRMTopic(crmTopicData) {
    const response = await axiosProvider.post(`/CRM/crmTopics`, crmTopicData);
    const crm = response.data;
    return crm
}

async function editCRMTopic(crmTopicData, crmId) {
    const response = await axiosProvider.put(`/CRM/crmTopics/${crmId}`, crmTopicData);
    const crm = response.data;
    return crm
}

async function removeCRMTopic(crmTopicId) {
    const response = await axiosProvider.delete(`/CRM/crmTopics/${crmTopicId}`);
    const crm = response.data;
    return crm
}

export { getAllCRM, getCRMByTopicId, createCRMTopic, editCRMTopic, removeCRMTopic }