import {
    getAllCRM,
    getCRMByTopicId,
    createCRMTopic,
    editCRMTopic,
    removeCRMTopic
} from "../../modules/admin/criticalRawMaterials/services/criticalRawMaterials.service";

const crmModule = {
    state: () => ({
        crm: [],
        crmBytopicId: [],
    }),

    getters: {
        obtainCrm(state) {
            return state.crm;
        },
        obtainCrmByTopicId(state) {
            return state.crmBytopicId;
        },
    },

    mutations: {
        setCRM(state, crm) {
            state.crm = crm;
        },
        setCRMByTopicId(state, crm) {
            if(crm){
                state.crmBytopicId = crm
            }
            else{
                state.crmBytopicId = []
            }
            
        },
        addCRMByTopicId(state, crm) {
            state.crmBytopicId.push(crm);
        },
        updateCRMByTopicId(state, crm) {
            state.crmBytopicId = [
                ...state.crmBytopicId.filter(c => c.id !== crm.id),
                crm
            ];
        },
        deleteCRMByTopicId(state, id) {
            state.crmBytopicId = [
                ...state.crmBytopicId.filter(c => c.id !== id)
            ];
        },

    },
    actions: {
        async obtainCrm({ commit }) {
            const crm = await getAllCRM();
            commit('setCRM', crm);
        },

        async obtainCRMByTopicId({ commit }, topicId) {
            const crm = await getCRMByTopicId(topicId);
            commit('setCRMByTopicId', crm);
        },

        async addCRMByTopicId({ commit }, crmForm) {
            const crm = await createCRMTopic(crmForm);
            commit('addCRMByTopicId', crm.data);
        },

        async updateCRMByTopicId({ commit }, { crmTopicData, crmId }) {
            const crm = await editCRMTopic(crmTopicData, crmId); 
            commit('updateCRMByTopicId', crm.data)
        },

        async deleteCRMByTopicId({ commit }, crmTopicId) {
            await removeCRMTopic(crmTopicId);
            commit('deleteCRMByTopicId', crmTopicId);
        },
    },
}

export default crmModule
