<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="pi pi-bars" style="font-size: 1.8rem; color: #c6c6c6"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <template v-if="user">
          <!-- User (Admin) Headers -->
          
          <ul class="navbar-nav me-auto mb-4 mb-lg-0" v-if="userRole != 'Analyst'">
            <!-- Documents -->
            <li class="nav-item">
              <router-link 
                class="nav-link" 
                active-link="active" 
                :to="{ name: 'Documents' }"
              >
                Documents
              </router-link>
            </li>
            <!-- Topic -->
            <li class="nav-item">
              <router-link 
                class="nav-link" 
                active-link="active" 
                :to="{
                  name: 'TopicSelectTopic',
                  query: { fromParent: false, edit: false },
                }"
              >
                Topics
              </router-link>
            </li>
            <!-- companies -->
            <li class="nav-item">
              <router-link class="nav-link" active-link="active" to="/admin/companies">Companies</router-link>
            </li>
            <!-- countries -->
            <li class="nav-item">
              <!-- <router-link
                class="nav-link"
                active-link="active"
                to="/admin/regions"
                >Regions</router-link
              > -->
              <router-link 
                class="nav-link" 
                active-link="active" 
                to="/admin/countries/filteredDocProg"
              >
                Countries
              </router-link>
            </li>
          </ul>

          <ul class="navbar-nav me-auto mb-4 mb-lg-0" v-else>
            <!-- Topic -->
            <li class="nav-item">
              <router-link 
                class="nav-link" 
                active-link="active" 
                :to="{
                  name: 'TopicSelectTopic',
                  query: { fromParent: false, edit: false },
                }"
              >
                Topics
              </router-link>
            </li>
          </ul>

          <!-- Users (Analyst, admins) headers -->
          <div v-if="selectedTopic"
            class="navbar-nav me-auto mb-4 mb-lg-0"
          >
            <label style="color:white; display: grid; place-items: center;">
              {{selectedTopic.name}}:
            </label>     

            <ul
              class="navbar-nav me-auto mb-4 mb-lg-0"
            >
              <li>
                <router-link
                  class="nav-link"
                  active-link="active"
                  to="/admin/topics/documents"
                >
                  Documents
                </router-link>
              </li>
              <li>
                <router-link
                  class="nav-link"
                  active-link="active"
                  to="/admin/programs"
                >
                  Programmes
                </router-link>
              </li>
              <li>
                <router-link
                  class="nav-link"
                  active-link="active"
                  to="/admin/topics/companies"
                >
                  Companies
                </router-link>
              </li>
              <li>
                <router-link
                  class="nav-link"
                  active-link="active"
                  to="/admin/topics/output"
                >
                  Export
                </router-link>
              </li>
              <li>
                <router-link
                  class="nav-link"
                  active-link="active"
                  to="/admin/topics/crm"
                >
                  CRM
                </router-link>
              </li>
            </ul>
          </div>

          <ul class="navbar-nav me-2 mb-2 mb-lg-0">
            <li class="nav-item me-3">
              <a class="nav-link nav-link-user">
                <!-- user -->
                <font-awesome-icon icon="fa-regular fa-user"  />
                <!-- {{
                  user != null && !!user?.userName ? user?.userName : 'Welcome'
                }} -->
                <div class="nav-link-user-mail">
                {{
                  user != null && !!user?.userName ? user?.userName : 'Welcome'
                }}
                </div>
              </a>
            </li>
            <!-- settings -->
            <div v-if="userRole != 'Analyst' ">
              <li class="nav-item dropdown me-3">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <!-- Settings -->
                  <font-awesome-icon icon="fa-regular fa-gear" />
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <router-link class="dropdown-item" to="/admin/regions">Regions</router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" to="/admin/countries">Countries</router-link>
                  </li>
                  <li>
                    <!-- TODO CHANGE PARAMS https://github.com/vuejs/router/blob/main/packages/router/CHANGELOG.md#414-2022-08-22 -->
                    <router-link class="dropdown-item" :to="{
                        name: 'TopicTopics',
                        query: { fromParent: false, edit: true },
                      }">Topics</router-link>
                  </li>
                  <!-- <li>
                    <router-link
                      class="dropdown-item"
                      :to="{
                        name: 'Programs',
                        params: { fromParent: false, edit: false },
                      }"
                      >Programs</router-link
                    >
                  </li> -->
                  <!-- <li>
                    <router-link class="dropdown-item" to="/admin/categories"
                      >Categories</router-link
                    >
                  </li> -->
                </ul>
              </li>
            </div>
            <!-- accessControl -->
            <div v-if="userRole != 'Analyst'">
              <li class="nav-item me-3">
                <router-link class="nav-link" to="/admin/access">
                  <!-- Access Control -->
                  <font-awesome-icon icon="fa-regular fa-users-gear" />
                </router-link>
              </li>
            </div>
            <li class="nav-item me-3" v-if="user == null">
              <router-link class="nav-link" to="/login"> 
                <!-- Login  -->
                <font-awesome-icon icon="fa-regular fa-arrow-right-to-bracket" />
              </router-link>
            </li>
            <li class="nav-item me-3" v-else>
              <a @click="logoutUser()" class="nav-link hover">
                <!-- logOut -->
                <font-awesome-icon icon="fa-regular fa-arrow-right-from-bracket" />
              </a>
            </li>
          </ul>
        </template>

        <ul class="navbar-nav" v-else>
          <li class="nav-item">
            <router-link class="nav-link" to="/login"> 
              <!-- Login  -->
              <font-awesome-icon icon="fa-regular fa-arrow-right-to-bracket" />
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {};
  },

  async created(){
    this.loadUserRole();
  },

  async mounted() {
    this.loadSelectedTopic();
    this.loadDocProgTopIds();
  },
  computed: {
    user() {
      return this.$store.getters.obtainUser;
    },
    userRole() {
      return this.$store.getters.obtainCurrentUserRole;
    },
    selectedTopic() {
      return this.$store.getters.obtainSelectedTopic;
    },
    docProgTopIds() { //documentTopicProgramIds for multiples programs list
      return this.$store.getters.obtainDocumentProgramTopicIds;
    }
  },
  methods: {
    logoutUser() {
      this.$store.dispatch('logoutUser');
      this.$store.dispatch('cleanSelectedTopic');
      this.$router.push({ path: '/login' });
      window.location.reload();
    },
    async loadUserRole() {
      const roleUser = JSON.parse(localStorage.getItem('role-mfkb'));
      if (roleUser != null) {
        await this.$store.dispatch('addCurrentUserRole', roleUser);
      }
    },
    async loadSelectedTopic() {
      const currentTopicId = JSON.parse(localStorage.getItem('topicId-mfkb'));
      if (currentTopicId != null) {
        await this.$store.dispatch('updateSelectedTopic', currentTopicId);
      }
    },
    async loadDocProgTopIds() {
      const Ids = JSON.parse(localStorage.getItem('docProgTopIds-mfkb'));
      if (Ids != null) {
        await this.$store.dispatch('addDocumentProgramTopicIds', Ids);
      }
    }

  },
  watch: {
    async user() {
      await this.loadUserRole();
      await this.loadSelectedTopic();
      await this.loadDocProgTopIds();
    },
  },
};
</script>

<style scoped>
nav {
  font-size: 1.2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
}

.vue-logo {
  width: 30px;
  height: 30px;
}

.hover {
  cursor: pointer;
}

.nav-link-user{
  position: relative;
}

.nav-link-user-mail {
  position: absolute;
  top: 115%;
  right: 0;
  color: white;
  background-color: rgba(108, 117, 125, 0.8);
  padding: 1rem;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-link-user:hover .nav-link-user-mail {
  opacity: 1;
  visibility: visible;
}


</style>
